export const mixinSocialAuth = {
    methods: {
        /*
        loginWithGoogle() {
            this.$gAuth
                .signIn()
                .then(GoogleUser => {
                    console.log('GoogleUser', GoogleUser)
                    console.log('getId', GoogleUser.getId())
                    console.log('basicprofile', GoogleUser.getBasicProfile().getName())
                    console.log('getBasicProfile', GoogleUser.getBasicProfile())
                    console.log('getAuthResponse', GoogleUser.getAuthResponse())

                    // TODO: get token (via googleId)
                })
                .catch(error => {
                    console.log('error', error)
                })
        },
        */

        loginWithFacebook() {
            window.FB.login(response => {
                if (response && response.authResponse) {
                    console.log('response', response)
                    window.FB.api(`/${response.authResponse.userID}`, userResponse => {
                        if (userResponse) {
                            console.log(userResponse);
                            // TODO: get token (via fbId)
                        }
                    }, this.params);
                }
            }, this.params)
        }
    },
}
