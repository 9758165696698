<template>
    <!-- register modal -->
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-lg">
            <div class="modal-content p-4">
                <div class="modal-header border-0 d-flex justify-content-center">
                    <span class="modal-title expert-detail-fields-of-experience-description-header mp-rbk mp-font-weight-semi-bold" id="register-modal-title">Registrierung</span>
                    <!--                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
                </div>
                <div class="modal-body py-0">
                    <div class="row">
                        <div class="col-12 text-center">
                            <p class="py-4 mp-qks mp-font-weight-medium font-size-after-registration-modal-description">Deine Registrierung war erfolgreich!</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-block border-0 mt-3">
                    <div class="row">
                        <div class="col-12 text-center">
                            <button type="button" class="btn btn-mp-green font-size-btn-course-detail-inquiry mp-font-weight-medium py-2 px-5 w-50" data-bs-dismiss="modal" @click="close">Schließen</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /register modal -->
</template>



<script>
    import BaseModal from "./BaseModal";


    export default {
        name: "RegisterUserSuccessInfo",

        extends: BaseModal,

        data() {
            return {
                modalId: 'register-expert-success-info',
                modal: undefined,
            }
        },

        methods: {
        }

    }
</script>



<style scoped>

</style>
