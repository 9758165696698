import Const from "./constants";

const apiPrefix = '/api';


export default {
    name: 'ApiEndpointMap',

    map: {
        // ------------------------------------------------------------< Categories >------
        category: {
            index: {
                url: `${apiPrefix}/category`,
                method: Const.restApi.METHOD_GET
            },
        },
        // -----------------------------------------------------------------< Areas >------
        area: {
            index: {
                url: `${apiPrefix}/area`,
                method: Const.restApi.METHOD_GET
            },
        },
        // ------------------------------------------------------------------< Tags >------
        tag: {
            index: {
                url: `${apiPrefix}/tag`,
                method: Const.restApi.METHOD_GET
            },
            indexInUse: {
                url: `${apiPrefix}/tag/in-use`,
                method: Const.restApi.METHOD_GET
            },
        },
        // ------------------------------------------------------------------< Insurances >------
        insurance: {
            index: {
                url: `${apiPrefix}/insurance`,
                method: Const.restApi.METHOD_GET
            },
        },
        // -----------------------------------------------------------------< Faqs >------
        faq: {
            index: {
                url: `${apiPrefix}/faq`,
                method: Const.restApi.METHOD_GET
            },
        },
        // -----------------------------------------------------------------< Contact >------
        contact: {
            msg2Admin: {
                url: `${apiPrefix}/contact`,
                method: Const.restApi.METHOD_POST
            },
        },
        // ---------------------------------------------------------------< Comments >------
        comment: {
            index: {
                url: `${apiPrefix}/expert/#expertUserId#/comments`,
                method: Const.restApi.METHOD_GET
            },
            show: {
                url: `${apiPrefix}/comment/#id#`,
                method: Const.restApi.METHOD_GET
            },
            create: {
                url: `${apiPrefix}/comment`,
                method: Const.restApi.METHOD_POST
            },
            update: {
                url: `${apiPrefix}/comment/#id#`,
                method: Const.restApi.METHOD_PUT
            },
            delete: {
                url: `${apiPrefix}/comment/#id#`,
                method: Const.restApi.METHOD_DELETE
            },
        },
        // -----------------------------------------------------------< CourseTypes >------
        courseType: {
            index: {
                url: `${apiPrefix}/coursetype`,
                method: Const.restApi.METHOD_GET
            },
        },
        // ---------------------------------------------------------------< Courses >------
        course: {
            index: {
                url: `${apiPrefix}/course`,
                method: Const.restApi.METHOD_GET
            },
            show: {
                url: `${apiPrefix}/course/#id#`,
                method: Const.restApi.METHOD_GET
            },
            create: {
                url: `${apiPrefix}/course`,
                method: Const.restApi.METHOD_POST
            },
            update: {
                url: `${apiPrefix}/course/#id#`,
                method: Const.restApi.METHOD_PUT
            },
            delete: {
                url: `${apiPrefix}/course/#id#`,
                method: Const.restApi.METHOD_DELETE
            },
            media: {
                index: {
                    url: `${apiPrefix}/course/#id#/media`,
                    method: Const.restApi.METHOD_GET
                },
                create: {
                    url: `${apiPrefix}/course/#id#/media`,
                    method: Const.restApi.METHOD_POST
                },
                delete: {
                    url: `${apiPrefix}/course/#id#/media/#mediaId#`,
                    method: Const.restApi.METHOD_DELETE
                },
            },
            inquiry: {
                url: `${apiPrefix}/course/#id#/inquiry`,
                method: Const.restApi.METHOD_POST
            }
        },
        // --------------------------------------------< subscriptions >------
        product: {
            index: {
                url: `${apiPrefix}/product`,
                method: Const.restApi.METHOD_GET
            },
            show: {
                url: `${apiPrefix}/product/#id#`,
                method: Const.restApi.METHOD_GET
            },
        },
        // ---------------------------------------------------------------< Experts >------
        expert: {
            register: {
                url: `${apiPrefix}/expert/register`,
                method: Const.restApi.METHOD_POST
            },
            index: {
                url: `${apiPrefix}/expert`,
                method: Const.restApi.METHOD_GET
            },
            show: {
                url: `${apiPrefix}/user/expert`,
                method: Const.restApi.METHOD_GET
            },
            update: {
                url: `${apiPrefix}/user/expert`,
                method: Const.restApi.METHOD_PUT
            },
            course: {
                index: {
                    url: `${apiPrefix}/user/expert/course`,
                    method: Const.restApi.METHOD_GET
                },
            },
            media: {
                create: {
                    url: `${apiPrefix}/user/expert/media`,
                    method: Const.restApi.METHOD_POST
                },
                delete: {
                    url: `${apiPrefix}/user/expert/media/#id#`,
                    method: Const.restApi.METHOD_DELETE
                },
            },
            emailExistsCheck: {
                url: `${apiPrefix}/register/email/check`,
                method: Const.restApi.METHOD_POST
            },
            expertise: {
                create: {
                    url: `${apiPrefix}/user/expert/expertise/#category_id#/#area_id#`,
                    method: Const.restApi.METHOD_POST
                },
                delete: {
                    url: `${apiPrefix}/user/expert/expertise/#area_id#`,
                    method: Const.restApi.METHOD_DELETE
                }
            }
        },
        // -------------------------------------< User: Registration, Auth, Profile >------
        user: {
            register: {
                url: `${apiPrefix}/register`,
                method: Const.restApi.METHOD_POST
            },
            registerViaFb: {
                url: `${apiPrefix}/register/fb`,
                method: Const.restApi.METHOD_POST
            },
            registerViaGoogle: {
                url: `${apiPrefix}/register/google`,
                method: Const.restApi.METHOD_POST
            },
            verification: {
                url: `${apiPrefix}/register/verify`,
                method: Const.restApi.METHOD_POST
            },
            verificationResendToken: {
                url: `${apiPrefix}/register/verify/resend-token`,
                method: Const.restApi.METHOD_POST
            },
            auth: {
                login: {
                    url: '/oauth/token',
                    method: Const.restApi.METHOD_POST
                },
                loginFB: {
                    url: `${apiPrefix}/login/fb`,
                    method: Const.restApi.METHOD_POST
                },
                loginGoogle: {
                    url: `${apiPrefix}/login/google`,
                    method: Const.restApi.METHOD_POST
                },
                loginApple: {
                    url: `${apiPrefix}/login/apple`,
                    method: Const.restApi.METHOD_POST
                },
            },
            fbProfile: {
                url: `${apiPrefix}/profile/fb`,
                method: Const.restApi.METHOD_POST
            },
            password: {
                requestToken: {
                    url: `${apiPrefix}/password/token`,
                    method: Const.restApi.METHOD_POST
                },
                set: {
                    url: `${apiPrefix}/password/change`,
                    method: Const.restApi.METHOD_PUT
                }
            },
            email: {
                requestToken: {
                    url: `${apiPrefix}/user/email/token`,
                    method: Const.restApi.METHOD_POST
                },
                set: {
                    url: `${apiPrefix}/user/email/change`,
                    method: Const.restApi.METHOD_PUT
                }
            },
            show: {
                url: `${apiPrefix}/user`,
                method: Const.restApi.METHOD_GET
            },
            update: {
                url: `${apiPrefix}/user`,
                method: Const.restApi.METHOD_PUT
            },
            delete: {
                url: `${apiPrefix}/user`,
                method: Const.restApi.METHOD_DELETE
            },
            newsletter: {
                subscribe: {
                    url: `${apiPrefix}/user/newsletter`,
                    method: Const.restApi.METHOD_POST
                },
                unsubscribe: {
                    url: `${apiPrefix}/user/newsletter`,
                    method: Const.restApi.METHOD_DELETE
                }
            }
        },
        // --------------------------------------------< subscriptions >------
        subscription: {
            index: {
                url: `${apiPrefix}/user/expert/subscription`,
                method: Const.restApi.METHOD_GET
            },
            show: {
                url: `${apiPrefix}/user/expert/subscription/#id#`,
                method: Const.restApi.METHOD_GET
            },
            create: {
                url: `${apiPrefix}/user/expert/subscription`,
                method: Const.restApi.METHOD_POST
            },
            cancel: {
                url: `${apiPrefix}/user/expert/subscription/#id#`,
                method: Const.restApi.METHOD_DELETE
            },
            coupon: {
                verify: {
                    url: `${apiPrefix}/user/expert/subscription/coupon/verify/#couponCode#`,
                    method: Const.restApi.METHOD_GET
                }
            },
            applyDiscount: {
                url: `${apiPrefix}/user/expert/subscription/discount/#couponCode#/apply`,
                method: Const.restApi.METHOD_POST
            },
            paymentMethod: {
                url: `${apiPrefix}/user/expert/subscription/payment-method`,
                method: Const.restApi.METHOD_GET
            }
        },
    }
}
