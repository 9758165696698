<template>
    <!-- register modal step 2 -->
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-md">
            <div class="modal-content p-4">
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-login-register-modal-title">Als Nutzer Registrieren</span>
                    <div class="container px-0 mt-4">
                        <div class="row">
                            <div class="col-12 px-0">
                                <input type="text" class="form-control mb-2 py-2 font-size-login-register-modal-input" id="register-username" placeholder="Wähle einen Benutzernamen" v-model.trim="$v.data.nickname.$model">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 px-0">
                                <input type="password" class="form-control mb-2 py-2 font-size-login-register-modal-input" id="register-password" placeholder="Gib ein Kennwort ein" v-model.trim="$v.data.password.$model">
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-12 px-0">
                                <input type="password" class="form-control mb-2 py-2 font-size-login-register-modal-input" id="register-password-repeat" placeholder="Wiederhole das Kennwort" v-model.trim="$v.data.passwordRepeat.$model">
                                <span class="mp-qks mp-font-weight-medium font-size-login-register-modal-password-addon">6 Zeichen oder länger. Kombiniere Groß-/Kleinbuchstaben und Zahlen.</span>
                            </div>
                        </div>
                        <div class="row mb-4" v-if="errorMsg">
                            <div class="col-12 px-0 text-danger text-center">
                                <b>{{ errorMsg }}</b>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer border-0 p-0">
                    <div class="container px-0">
                        <div class="row mb-4">
                            <div class="col-12">
                                <button type="button"
                                        class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100"
                                        :disabled="$v.$invalid"
                                        @click="doRegister"
                                >
                                    Registrieren
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <span class="mp-qks mp-font-weight-semi-bold font-size-login-register-modal-bottom-addon">Bereits registriert?</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <button type="button"
                                        class="btn py-0 px-5 btn-login-register-modal-switch-modal font-size-btn-login-register-modal-switch-modal mp-font-weight-semi-bold"
                                        @click="switchToLogin"
                                >
                                    HIER ANMELDEN
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- toasts -->
        <Toast v-if="showToastFailure" title="Anlegen des Benutzer-Kontos fehlgeschlagen!" :msg="errorMsg" background-color="#842029" :delay="15000" @close="showToastFailure = false" />
    </div>
    <!-- /register modal step 2 -->
</template>



<script>
    import BaseModal from "./BaseModal";
    import {required, sameAs} from "vuelidate/lib/validators";
    import {mixinBasics} from "../../mixins/basics";
    import Toast from "../../components/Toast";
    import eventBus from "../../eventbus";
    import Const from "../../constants";


    export default {
        name: "RegisterUserViaEMail",

        extends: BaseModal,

        components: {
            Toast
        },

        mixins: [
            mixinBasics
        ],

        props: {
            email: {
                required: true,
                type: String
            },
            callback: {
                required: true,
                type: Function
            }
        },

        data() {
            return {
                keyPrefix: 'registerUserViaEMail',
                modalId: 'register-user-via-email-modal',
                modal: undefined,
                showToastFailure: false,
                errorMsg: undefined,
                acceptGdpr: undefined,
                data: {
                    nickname: undefined,
                    password: undefined,
                    passwordRepeat: undefined,
                },
            }
        },

        validations() {
            return {
                data: {
                    nickname: { required },
                    password: { required },
                    passwordRepeat: {
                        sameAsPassword: sameAs('password')
                    },
                }
            };
        },

        methods: {
            async doRegister() {
                let res = await this.callback(Object.assign({}, this.data, {email: this.email}));
                if (res.error === 0) {
                    this.$emit('success');
                    this.close();
                } else {
                    let errCode = res.error.data.error.toString();
                    this.errorMsg = Const.errors[errCode];
                    this.showToastFailure = true;
                }
            },
            switchToLogin() {
                eventBus.$emit(Const.events.SHOW_LOGIN);
                this.close();
            }
        },

    }
</script>



<style scoped>

</style>