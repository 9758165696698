import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);


const routes = [
    // landing
    {
        path: '/',
        meta: { title: 'index'},
        component: () => import('../views/Index.vue')
    },
    // ***********************************************************************<[ user ]>*****
    {
        path: '/email/change/:emailPin',
        props: true,
        component: () => import('../views/Index.vue')
    },
    {
        path: '/password/change/:passwordPin',
        props: true,
        component: () => import('../views/Index.vue')
    },
    {
        path: '/register/verify/:regPin',
        props: true,
        component: () => import('../views/Index.vue')
    },
    {
        path: '/user/account',
        meta: { title: 'user/account'},
        props: true,
        component: () => import('../views/user/UserAccount')
    },
    // ***********************************************************************<[ expert account ]>*****
    {
        path: '/expert/register',
        meta: { title: 'register'},
        component: () => import('../views/expert/ExpertRegister.vue')
    },
    {
        path: '/expert/account',
        meta: { title: 'expert/account'},
        props: true,
        component: () => import('../views/expert/ExpertAccount.vue')
    },
    {
        path: '/expert/account/:preselectedTab/:paymentStatus',
        meta: { title: 'expert/account'},
        props: true,
        component: () => import('../views/expert/ExpertAccount.vue')
    },
    {
        path: '/expert/account/:preselectedTab',
        meta: { title: 'expert/account'},
        props: true,
        component: () => import('../views/expert/ExpertAccount.vue')
    },
    // ***********************************************************************<[ expert / Overview / Detail ]>*****
    {
        path: '/experten/:metaExpert/:id/detail',
        props: true,
        component: () => import('../views/expert/ExpertDetail.vue')
    },
    {
        path: '/experten',
        meta: { title: 'expert'},
        props: true,
        component: () => import('../views/expert/Overview.vue')
    },
    {
        path: '/experten/:searchTerm',
        props: true,
        component: () => import('../views/expert/Overview.vue')
    },
    {
        path: '/experten/:metaCategory/:categoryId',
        props: true,
        component: () => import('../views/expert/Overview.vue')
    },
    {
        path: '/experten/:metaCategory/:categoryId/:metaArea/:areaId',
        props: true,
        component: () => import('../views/expert/Overview.vue')
    },

    // *********************************************************************<[ category ]>*****
    {
        path: '/fachgebiete',
        component: () => import('../views/category/Category.vue')
    },
    {
        path: '/fachgebiete/:metaCategory/:categoryId',
        props: true,
        component: () => import('../views/expert/Overview.vue')
    },
    {
        path: '/fachgebiete/:metaCategory/:categoryId/:metaArea/:areaId',
        props: true,
        component: () => import('../views/expert/Overview.vue')
    },
    // ***********************************************************************<[ course ]>*****
    {
        path: '/kurse',
        meta: { title: 'course'},
        props: true,
        component: () => import('../views/course/Overview.vue')
    },
    {
        path: '/kurse/:metaCourse/:id/detail',
        props: true,
        component: () => import('../views/course/CourseDetail.vue')
    },
    // *********************************************************************<[ register ]>*****
    {
        path: '/register/expert/info',
        meta: { title: 'registerExpert'},
        component: () => import('../views/expert/ExpertRegisterInfo.vue')
    },
    // *****************************************************************<[ subscription ]>*****
    {
        path: '/subscription',
        meta: { title: 'subscription'},
        component: () => import('../views/subscription/Subscription')
    },
    {
        path: '/subscription/create',
        meta: { title: 'subscription'},
        component: () => import('../views/subscription/SubscriptionCreate')
    },
    {
        path: '/subscription/create/success',
        meta: { title: 'subscription'},
        component: () => import('../views/subscription/SubscriptionCreateSuccess')
    },
    {
        path: '/subscription/:id',
        meta: { title: 'subscription'},
        props: true,
        component: () => import('../views/subscription/Subscription')
    },
    // **************************************************************************<[ about ]>*****
    {
        path: '/ueber-uns',
        meta: { title: 'faq'},
        component: () => import('../views/about/About.vue')
    },
    // **************************************************************************<[ imprint ]>*****
    {
        path: '/impressum',
        meta: { title: 'imprint'},
        component: () => import('../views/imprint/Imprint.vue')
    },
    // **************************************************************************<[ faq ]>*****
    {
        path: '/faq',
        meta: { title: 'faq'},
        component: () => import('../views/faq/Faq.vue')
    },
    // **************************************************************************<[ glossary ]>*****
    {
        path: '/glossar',
        meta: { title: 'glossary'},
        component: () => import('../views/glossary/Glossary.vue')
    },
    // **********************************************************************<[ contact ]>*****
    {
        path: '/kontakt',
        meta: { title: 'contact'},
        component: () => import('../views/contact/Contact.vue')
    },
    // ***************************************************************<[ 404 / CatchAll ]>*****
    {
        path: '*',
        component: () => import('../views/Error404.vue')
    }
];


const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
