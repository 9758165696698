export default {

    name: 'Constants',

    getters: {},

    mutations: {},

    actions: {},

    profile: {
        AVATAR_IMAGE_DEFAULT: '../images/avatar_default.jpg'
    },

    filter: {
        overview: {
            NAME_EMAIL: 'nameEmail',
            CUSTOMER_TYPE: 'customerType',
            VERIFIED: 'verified',
            NEW_IMAGES: 'newImages',
        }
    },

    application: {
        MODE_PRODUCTION: 'production',
        MODE_DEV: 'development',
        MODE_TESTING_OFFICE: 'testing_office',
        MODE_TESTING_HOME: 'testing_home',
        MODE_TESTING_NGROK: 'testing_ngrok',
    },

    restApi: {
        METHOD_POST: 'POST',
        METHOD_DELETE: 'DELETE',
        METHOD_GET: 'GET',
        METHOD_PUT: 'PUT',
        PARAM_NAME_ID: '##ID##'
    },

    auth: {
        AUTH_TOKEN: 'authToken',
    },

    alerts: {
        types: {
            SUCCESS: 'alert-success',
            WARNING: 'alert-warning',
            FAILURE: 'alert-danger',
            INFO: 'alert-info',
        },
        icons: {
            SUCCESS: 'fa-check-circle',
            WARNING: 'fa-exclamation-triangle',
            FAILURE: 'fa-times-circle',
            HORRIBLE_FAILURE: 'fa-skull-crossbones',
            OH_SHIT: 'fa-poo',
            INFO: 'fa-info-circle',
        }
    },

    events: {
        SHOW_LOGIN: 'showLogin',
        LOGIN_SUCCESS: 'loginSuccess',
        DO_LOGIN: 'doLogin',
        DO_LOGOUT: 'doLogin',
        SHOW_REGISTER_USER: 'showRegisterUser',
        SHOW_REGISTER_USER_VIA_EMAIL: 'showRegisterUserViaEMail',
        SHOW_REGISTER_USER_SUCCESS: 'showRegisterUserSuccess',
        DO_REGISTER: 'doRegister',
        DO_SEARCH: 'doSearch',
        SHOW_PASSWORD_FORGOT: 'showPasswortForgot',
        DO_REGISTER_VIA_GOOGLE: 'doRegViaGoogle',
        DO_REGISTER_VIA_FB: 'doRegViaFb',
        DO_REGISTER_VIA_EMAIL: 'doRegViaEmail'
    },

    errors: {
        '-50004': 'Diese Emailadresse ist bereits in Verwendung!',
        '-50012': 'Der eingegebene Code ist ungültig.'
    },

    roles: {
        EXPERT: 'expert',
        USER: 'user'
    },

    registration: {
        PAYMENT_INFO: '',
        PRIVACY_INFO: ''
    },

    academicTitles: {
        leading: [
            'Dr. med. univ.',
            'Dr. phil.',
            'Dr. rer. nat.',
            'Dr. rer. cur.',
            'Dipl. Päd.',
            'Mag. pharm.',
            'Mag. phil.',
            'Mag. rer. nat.',
            'Mag. art.',
            'DI',
            'Prof.'
        ],
        trailing: [
            'BSc',
            'BEd',
            'BA',
            'B.phil.',
            'LLB. oec.',
            'BArch',
            'LL.B.',
            'BSSc',
            'BTh',
            'Bakk. Sport.',
            'Bakk. Soz.',
            'BScMed',
            'BScN',
            'Bakk. Komm.',
            'Bakk. rer. nat.',
            'MA',
            'M.phil.',
            'LLM. oec.',
            'LLM',
            'M.A.',
            'MEd',
            'MSc',
            'MIBI',
            'MPOS',
            'MP',
            'MSc',
            'PhD'
        ]
    },

    filterListMode: {
        EXPERTS: 1,
        COURSES: 2
    },

    filterSortBy: {
        NAME: 1,
        DISTANCE: 2,
        LEVEL: 3
    }
};
