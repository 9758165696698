import Constants from './constants';
import store from './store/index';
import axios from 'axios';
import Configs from './configs';


export const apiCall = async function (url, method = Constants.config.restApi.METHOD_GET, payload = {}, dontAddPayloadToParams = false)
{
    axios.defaults.baseURL = Configs.restApi.baseURL[Configs.applicationMode];
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    let req = {
        method: method,
        url: url,
        data: {},
        params: {},
        headers: {dontAddPayloadToParams: dontAddPayloadToParams}
    };

    if (payload instanceof FormData) {
        req.data = payload;
        req['config'] = {headers: {'Content-Type': 'multipart/form-data'}};
    } else {
        Object.keys(payload).forEach((k) => {
            req.data[k] = payload[k];
            if (!dontAddPayloadToParams) {
                req.params[k] = payload[k];
            }
        });
    }

    // authToken (if available) will be attached to every request

    //let token = store().getters['main/getUserAuthToken'];
    let token = store().state['main'].user.token;
    axios.defaults.headers.Authorization = token ? `Bearer ${token}` : undefined;

    // AppSecret gets added also
    if (dontAddPayloadToParams === true) {
        req.data['app_secret'] = Configs.restApi.appSecret[Configs.applicationMode];
    } else {
        req.params['app_secret'] = Configs.restApi.appSecret[Configs.applicationMode];
    }

    // remove flag
    delete req.headers['dontAddPayloadToParams'];

    return await axios(req)
        .then(res => {
            return {data: res.data.data, error: res.data.error ?? undefined};
            // return res.data;
        })
        .catch(err => {
            return {data: undefined, error: err.response};
            //return err.response;
        });
};



export const apiCallOauth = async function (url, method = Constants.config.restApi.METHOD_GET, payload = {}, dontAddPayloadToParams = false)
{
    axios.defaults.baseURL = Configs.restApi.baseURL[Configs.applicationMode];
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    let req = {
        method: method,
        url: url,
        data: {},
        params: {},
    };

    Object.keys(payload).forEach((k) => {
        req.data[k] = payload[k];
        if (!dontAddPayloadToParams) {
            req.params[k] = payload[k];
        }
    });

    return await axios(req)
        .then( res => {
            return res.data;
        })
        .catch( err => {
            return err.response;
        });
};



/*
    buildFinalUrl

    accepts an url, and replaces existing param-placeholders (like #myParam#),
    with their corresponding values, taken from the 'params'-object

    url     -> string
    params  -> { name: value, name: value, ... }
 */
export const buildFinalUrl = (url, params) => {
    const mandatoryParams = url.matchAll(/#(\w+)#/gi);
    let urlFinal = url;

    for (let param = mandatoryParams.next(); param.value !== undefined; param = mandatoryParams.next()) {
        let paramFieldName = param.value[0];
        let paramName = param.value[1];
        let paramValue = params[paramName];

        if (paramValue === undefined) {
            throw(`buildFinalUrl: mandatory param.: '${paramName}' in '${url}' is missing!`);
        }
        urlFinal = urlFinal.replace(paramFieldName, paramValue);
    }
    return urlFinal;
};




export const apiCallToFileDownload = async function (url, filename, method = Constants.config.restApi.METHOD_GET, payload = {}, dontAddPayloadToParams = false)
{
    axios.defaults.baseURL = Configs.restApi.baseURL[Configs.applicationMode];
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    let req = {
        method: method,
        url: url,
        data: {},
        params: {},
        headers: {dontAddPayloadToParams: dontAddPayloadToParams},
        responseType: 'blob' // <---------- important!!!!
    };

    if (payload instanceof FormData) {
        req.data = payload;
        req['config'] = {headers: {'Content-Type': 'multipart/form-data'}};
    } else {
        Object.keys(payload).forEach((k) => {
            req.data[k] = payload[k];
            if (!dontAddPayloadToParams) {
                req.params[k] = payload[k];
            }
        });
    }

    // authToken (if available) will be attached to every request
    let token = store().getters['main/getUserAuthToken'];
    if (token) {
        axios.defaults.headers.Authorization = `Bearer ${token}`;
    }

    // AppSecret gets added also
    if (dontAddPayloadToParams === true) {
        req.data['app_secret'] = Configs.restApi.appSecret[Configs.applicationMode];
    } else {
        req.params['app_secret'] = Configs.restApi.appSecret[Configs.applicationMode];
    }

    // remove flag
    delete req.headers['dontAddPayloadToParams'];

    return await axios(req)
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => {
            return {data: undefined, error: err.response};
            //return err.response;
        });
};



export const fileDownload = async function (url, filename)
{
    axios.defaults.baseURL = Configs.restApi.baseURL[Configs.applicationMode];
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    let req = {
        method: 'GET',
        url: url,
        data: {},
        params: {},
        headers: {},
        responseType: 'blob' // <---------- important!!!!
    };

    // authToken (if available) will be attached to every request
    let token = store().getters['main/getUserAuthToken'];
    if (token) {
        axios.defaults.headers.Authorization = `Bearer ${token}`;
    }

    return await axios(req)
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => {
            return {data: undefined, error: err.response};
        });
};



export const isUserAuthenticated = function () {
    return store().getters['main/isUserAuthenticated'];
};


export const parseStr2Time = function(str) {
    console.log(str);
    if(str.length === 1 && parseInt(str) > 2) {
        console.log('fix 1: ' + `0${str}:`);
        return `0${str}:`;
    }
    if(str.length === 4 && parseInt(str.slice(3)) > 5) {
        console.log('fix 2: ' + `${str.slice(0,2)}:0${str.slice(3)}`);
        return `${str.slice(0,2)}:0${str.slice(3)}`;
    }
    let res = str.matchAll(/([01]\d|2[0-3]):?([0-5]\d)/gi).next().value;
    return res ? `${res[1]}:${res[2]}` : str;
};




/*
export const getInitialDateRange = function() {
    let currDate = new Date();
    return [
        moment(currDate).subtract(Constants.sighting.NUMBER_OF_DAYS_TO_DISPLAY_INITIALLY, 'days').format('YYYY-MM-DD'),
        moment(currDate).format('YYYY-MM-DD')
    ];
}
*/
