<template>
    <!-- register modal -->
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-md">
            <div class="modal-content p-4">
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-login-register-modal-title">Als Nutzer registrieren</span>
                    <div class="container px-0 mt-4">
                        <div class="row">

                            <!-- btn: FB -->
                            <!--
                            <div class="col-12" style="padding: 0px 14px 0px 26px">
                                <button
                                    v-if="!userKnownData"
                                    type="button"
                                    class="btn btn-mp-sm w-100 mb-2 bg-fb-blue text-white mp-qks mp-font-weight-medium font-size-registration-expert-sm-btn text-center py-2"
                                    @click="getFbProfile"
                                >
                                    <i class="fab fa-facebook-f me-2" style="float: left; padding-top: 4px"></i><span>Weiter mit Facebook</span>
                                </button>
                            </div>
                            -->
                            <!-- btn: Google -->
                            <!--
                            <div class="col-12 px-0">
                                <div class="col-12 m-0" style="padding: 0px 26px 0px 26px" v-if="!userKnownData">
                                    <div
                                        ref="googleLoginBtn"
                                        id="googleLoginBtn"
                                    />
                                </div>
                            </div>
                            -->

                            <!-- f...book / mobile -->
                            <div class="col-12 d-xs-block d-md-none" style="min-width: 325px; max-width: 325px;">
                                <button
                                    v-if="!userKnownData"
                                    type="button"
                                    class="btn btn-mp-sm w-100 mb-2 bg-fb-blue text-white mp-qks mp-font-weight-medium font-size-registration-expert-sm-btn text-center py-2"
                                    @click="getFbProfile"
                                >
                                    <i class="fab fa-facebook-f me-2" style="float: left; padding-top: 4px"></i><span>Weiter mit Facebook</span>
                                </button>
                            </div>
                            <!-- f...book / web -->
                            <div class="col-12 d-none d-md-block" style="padding: 0px 14px 0px 26px">
                                <button
                                    v-if="!userKnownData"
                                    type="button"
                                    class="btn btn-mp-sm w-100 mb-2 bg-fb-blue text-white mp-qks mp-font-weight-medium font-size-registration-expert-sm-btn text-center py-2"
                                    @click="getFbProfile"
                                >
                                    <i class="fab fa-facebook-f me-2" style="float: left; padding-top: 4px"></i><span>Weiter mit Facebook</span>
                                </button>
                            </div>

                            <!-- g..gle / mobile -->
                            <div class="col-12 m-0 d-xs-block d-md-none" >
                                <div :ref="`${modalId}googleLoginBtnMobile`" :id="`${modalId}googleLoginBtnMobile`" v-if="!userKnownData"></div>
                            </div>
                            <!-- g..gle / web -->
                            <div class="col-12 m-0 d-none d-md-block" style="padding: 0px 26px 0px 26px">
                                <div :ref="`${modalId}googleLoginBtnWeb`" :id="`${modalId}googleLoginBtnWeb`" v-if="!userKnownData"></div>
                            </div>

                            <div v-if="userIsAlreadyRegistered" class="text-danger py-2" style="font-weight: bold">
                                Dieser Benutzer ist bereits registriert!
                            </div>
                            <div class="col-12 px-0">
                                <div ref="googleLoginBtn" id="googleLoginBtn"></div>
                            </div>


                        </div>

                        <!--
                        <div class="row">
                            <div class="col-12 px-0">
                                <button type="button" class="btn btn-mp-sm w-100 mp-qks mp-font-weight-medium font-size-registration-expert-sm-btn text-lg-start py-2">
                                    <i class="fab fa-apple me-2"></i> Weiter mit Apple
                                </button>
                            </div>
                        </div>
                        -->

                        <div class="row my-4" v-if="!uid_fb && !userKnownData">
                            <div class="col-4 col-md-5 ps-0">
                                <hr />
                            </div>
                            <div class="col-4 col-md-2 d-flex justify-content-center">
                                <span class="login-modal-or-text font-size-login-modal-or-text mp-font-weight-semi-bold align-self-center mp-qks">ODER</span>
                            </div>
                            <div class="col-4 col-md-5 pe-0">
                                <hr />
                            </div>
                        </div>

                        <div class="row mb-4">
                            <div class="col-12 px-0">
                                <input
                                    :disabled="userKnownData"
                                    type="email"
                                    class="form-control mb-2 py-2 font-size-login-register-modal-input"
                                    id="register-email"
                                    placeholder="Gib deine E-Mail Adresse ein"
                                    v-model.trim.lazy="$v.email.$model"
                                    :style="$v.email.$error ? 'border: red 2px solid' : ''"
                                >
                            </div>
                        </div>
                        <!--                    <div class="row">-->
                        <!--                        <div class="col-12 px-0">-->
                        <!--                            <input type="text" class="form-control mb-2 py-2 font-size-login-register-modal-input" id="register-username" placeholder="Wähle einen Benutzernamen">-->
                        <!--                        </div>-->
                        <!--                    </div>-->
                        <!--                    <div class="row">-->
                        <!--                        <div class="col-12 px-0">-->
                        <!--                            <input type="password" class="form-control mb-2 py-2 font-size-login-register-modal-input" id="register-password" placeholder="Gib ein Kennwort ein">-->
                        <!--                        </div>-->
                        <!--                    </div>-->
                        <!--                    <div class="row mb-4">-->
                        <!--                        <div class="col-12 px-0">-->
                        <!--                            <input type="password" class="form-control mb-2 py-2 font-size-login-register-modal-input" id="register-password-repeat" placeholder="Wiederhole das Kennwort">-->
                        <!--                            <span class="mp-qks mp-font-weight-medium font-size-login-register-modal-password-addon">6 Zeichen oder länger. Kombiniere Groß- und Kleinbuchstaben und Zahlen.</span>-->
                        <!--                        </div>-->
                        <!--                    </div>-->
                        <div class="row mb-4">
                            <div class="col-12 px-0">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" v-model="acceptGdpr" id="login-register-modal-accept-gdpr">
                                    <label class="form-check-label mp-qks font-size-login-register-modal-checkboxes mp-font-weight-medium" for="login-register-modal-accept-gdpr">
                                        Ich akzeptiere <a class="registration-expert-links" :href="`${documentsUrl}/Nutzungsbedingungen_merge_plate.pdf`" target="_blank">Nutzungs-</a> und
                                        <a class="registration-expert-links" :href="`${documentsUrl}/Datenschutzerklärung_merge_plate.pdf`" target="_blank">Datenschutzerklärung</a>.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!---- final Registration-Buttons --->
                <div class="modal-footer border-0 p-0">
                    <div class="container px-0">
                        <div class="row mb-4">
                            <div class="col-12">
                                <button
                                    v-if="uid_fb || accessTokenFb"
                                    type="button"
                                    class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100"
                                    @click="doRegisterViaFb"
                                    :disabled="$v.$invalid || !acceptGdpr"
                                >
                                    Registrieren (via Facebook)
                                </button>

                                <button
                                    v-if="uid_google"
                                    type="button"
                                    class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100"
                                    @click="doRegisterViaGoogle"
                                    :disabled="$v.$invalid || !acceptGdpr"
                                >
                                    Registrieren (via Google)
                                </button>

                                <button
                                    v-if="!uid_fb && !uid_google && !accessTokenFb"
                                    type="button"
                                    class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100"
                                    @click="doRegisterViaEMail"
                                    :disabled="$v.$invalid || !acceptGdpr"
                                >
                                    Registrieren
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <span class="mp-qks mp-font-weight-semi-bold font-size-login-register-modal-bottom-addon">Bereits registriert?</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <button type="button"
                                        class="btn py-0 px-5 btn-login-register-modal-switch-modal font-size-btn-login-register-modal-switch-modal mp-font-weight-semi-bold"
                                        @click="switchToLogin"
                                >
                                    HIER ANMELDEN
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /register modal -->
</template>



<script>
    import BaseModal from "./BaseModal";
    import {email, required} from "vuelidate/lib/validators";
    import eventBus from "../../eventbus";
    import Const from "../../constants";
    import {mixinBasics} from "../../mixins/basics";
    import {mapActions} from "vuex";
    import Configs from "../../configs";
    import jwt_decode from "jwt-decode";


    export default {
        name: "RegisterUser",

        extends: BaseModal,

        mixins: [
            mixinBasics,
            //mixinSocialAuth
        ],

        props: {
            userKnownData: {
                type: Object,
                required: false,
                default: undefined
            }
        },

        data() {
            return {
                modalId: 'register-user-modal',
                modal: undefined,
                isLoading: false,

                acceptGdpr: false,
                uid_google: undefined,
                uid_fb: undefined,
                accessTokenFb: undefined,
                email: undefined,
                firstname: undefined,
                lastname: undefined,
                userIsAlreadyRegistered: false
            }
        },

        validations: {
            email: { email, required },
        },

        methods: {
            ...mapActions('main', {
                _getFbProfile: 'getFbProfile'
            }),

            doRegisterViaEMail() {
                eventBus.$emit(Const.events.SHOW_REGISTER_USER_VIA_EMAIL, {email: this.email});
                this.close();
            },

            doRegisterViaGoogle() {
                eventBus.$emit(Const.events.DO_REGISTER_VIA_GOOGLE, {
                    googleUid: this.uid_google,
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email
                });
                this.close();
            },

            doRegisterViaFb() {
                eventBus.$emit(Const.events.DO_REGISTER_VIA_FB, this.accessTokenFb);
                this.close();
            },

            async getFbProfile() {
                await window.FB.login(async (resFb) => {
                    console.log(resFb);
                    if (resFb.status === 'connected' && resFb['authResponse'] !== undefined) {
                        // get profile data via BE
                        let res = await this._getFbProfile(resFb.authResponse.accessToken);
                        this.userIsAlreadyRegistered = res.isAlreadyRegisteredWithUs;

                        if (!this.userIsAlreadyRegistered) {
                            this.uid_google = undefined;    // reset possible google-id to prevent "UI-Button-chaos"
                            this.uid_fb = res.id;
                            this.email = res.email;
                            this.accessTokenFb = resFb.authResponse.accessToken;
                        }
                    }
                }, { scope: 'public_profile,email', return_scopes: true })
            },

            async createGoogleLogin(btnRef, btnWidth = 400) {
                await window.google.accounts.id.initialize({
                    client_id: Configs.googleAuthOptions.client_id,
                    scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
                    ux_mode: 'popup',
                    callback: (response) => {
                        this.getGoogleProfile(response);
                    },
                });
                await window.google.accounts.id.renderButton(
                    document.getElementById(btnRef),
                    {
                        theme: "outline",
                        size: "large",
                        width: btnWidth,
                    }  // customization attributes
                );
            },

            async getGoogleProfile(e) {
                // decode response
                console.log('decrypted:');
                let res = jwt_decode(e.credential);
                console.log(res);
                this.uid_fb = undefined;    // reset possible fb-id to prevent "UI-Button-chaos"
                this.uid_google = res.sub;
                this.firstname = res.given_name;
                this.lastname = res.family_name;
                this.email = res.email;
            },

            switchToLogin() {
                eventBus.$emit(Const.events.SHOW_LOGIN);
                this.close();
            }
        },

        async created() {
            console.log('userKnownData:'); console.log(this.userKnownData);
            if (this.userKnownData) {
                switch(this.userKnownData.loginType) {
                    case 'fb':
                        this.email = this.userKnownData.email;
                        this.accessTokenFb = this.userKnownData.accessToken;
                        break;
                    case 'google':
                        this.email = this.userKnownData.email;
                        this.uid_google = this.userKnownData.googleUid;
                        this.firstname = this.userKnownData.firstname;
                        this.lastname = this.userKnownData.lastname;
                }
            } else {
                await this.createGoogleLogin(`${this.modalId}googleLoginBtnMobile`, 300);
                await this.createGoogleLogin(`${this.modalId}googleLoginBtnWeb`);
            }

        }

    }
</script>



<style scoped>

</style>