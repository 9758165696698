<template>
    <div id="app" v-if="isStoreInitDone">

        <!-- navigation -->
        <Navigation />

        <!-- content -->
        <router-view :key="$route.path" />

        <!-- footer -->
        <Footer />

        <!-- dialogs -->
        <Login v-if="showDlgLogin" @success="doRouting" @close="showDlgLogin=false" />

        <RegisterUser
            v-if="showDlgRegisterUser"
            :user-known-data="userKnownData"
            @close="showDlgRegisterUser = false"
        />

        <RegisterUserViaEMail
            v-if="showDlgRegisterUserViaEMail"
            :email="registerData.email"
            :callback="doRegisterUserViaEMail"
            @close="showDlgRegisterUserViaEMail = false"
            @success="showDialogRegisterVerifyEMail"
        />

        <EMailVerifyInfo
            v-if="showDlgRegisterUserVerifyEMail"
            :email="registerData.email"
            :callback-verification="doRegisterUserVerify"
            :callback-resend-token="doRegisterUserResendVerificationToken"
            @close="showDlgRegisterUserVerifyEMail = false"
        />

        <PasswordChangeReq v-if="showDlgPasswordForgot" @close="showDlgPasswordForgot = false" />
        <RegisterUserSuccessInfo v-if="showDlgRegisterUserSuccess" @click="showDlgRegisterUserSuccess = false" />
        <RegisterUserFailedInfo v-if="showDlgRegisterUserFailed" @click="showDlgRegisterUserFailed  = false" />
    </div>
</template>



<script>
    import Navigation from "./components/Navigation";
    import Footer from "./components/Footer";
    import {mapActions, mapState} from "vuex";
    import eventBus from "./eventbus";
    import Const from "./constants";
    import Login from "./components/modals/Login";
    import RegisterUser from "./components/modals/RegisterUser";
    import RegisterUserViaEMail from "./components/modals/RegisterUserViaEMail";
    import PasswordChangeReq from "./components/modals/PasswordChangeReq";
    import EMailVerifyInfo from "./components/modals/EMailVerifyInfo";
    import RegisterUserSuccessInfo from "./components/modals/RegisterUserSuccessInfo";
    import RegisterUserFailedInfo from "./components/modals/RegisterUserFailedInfo";
    import {mixinBasics} from "./mixins/basics";


    export default {
        name: "App",

        metaInfo() {
            return {
                titleTemplate: '%s | mergeplate.com',
                title: 'Home',
            };
        },

        mixins: [
          mixinBasics
        ],

        data() {
            return {
                showDlgLogin: false,
                showDlgRegisterUser: false,
                showDlgRegisterUserViaEMail: false,
                showDlgRegisterUserViaFb: false,
                showDlgRegisterUserVerifyEMail: false,
                showDlgRegisterUserSuccess: false,
                showDlgRegisterUserFailed: false,
                showDlgPasswordForgot: false,

                userKnownData: undefined,

                registerData: {
                    email: undefined,
                    nickname: undefined,
                    password: undefined,
                }
            }
        },

        components: {
            RegisterUserFailedInfo,
            RegisterUserSuccessInfo,
            EMailVerifyInfo,
            PasswordChangeReq,
            RegisterUserViaEMail,
            RegisterUser,
            Login,
            Navigation,
            Footer,
        },

        computed: {
            ...mapState('main', {
                isStoreInitDone: 'storeInitialized',
            }),
        },

        methods: {
            ...mapActions('main', {
                _logout: 'userLogout',
                _registerUserViaEMail: 'registerUserViaEMail',
                _registerUserViaFb: 'registerUserViaFb',
                _registerUserViaGoogle: 'registerUserViaGoogle',
                _registerUserVerify: 'registerUserVerify',
                _registerUserResendVerificationToken: 'registerUserResendVerificationToken'
            }),

            showDialogRegisterViaEMail(data) {
                this.registerData.email = data.email;
                this.showDlgRegisterUserViaEMail = true;
            },

            showDialogRegisterVerifyEMail() {
                this.showDlgRegisterUserVerifyEMail = true;
            },

            async doRegisterUserViaEMail(data) {
                return await this._registerUserViaEMail(data);
            },

            async doRegisterUserViaFb(data) {
                if (await this._registerUserViaFb(data)) {
                    this.showDlgRegisterUserSuccess = true;
                } else {
                    this.showDlgRegisterUserFailed = true;
                }
            },

            async doRegisterUserViaGoogle(data) {
                if (await this._registerUserViaGoogle(data)) {
                    this.showDlgRegisterUserSuccess = true;
                } else {
                    this.showDlgRegisterUserFailed = true;
                }
            },

            async doRegisterUserVerify(data) {
                return await this._registerUserVerify(data);
            },

            async doRegisterUserResendVerificationToken(data) {
                return await this._registerUserResendVerificationToken(data);
            },

            doRouting() {
                switch (this.profile.role) {
                    case 'expert':
                        this.$router.push('/expert/account')
                        break;
                    case 'user':
                        this.$router.push('/user/account')
                        break;
                }
            },

            doLogout() {
                this._logout();
                this.$router.push('/');
            }
        },

        created() {
            eventBus.$on(Const.events.SHOW_LOGIN, () => {
                console.log('event: SHOW_LOGIN');
                this.showDlgLogin = true;
            });
            eventBus.$on(Const.events.LOGIN_SUCCESS, () => {
                console.log('event: SHOW_LOGIN');
                this.showDlgLogin = true;
            });
            eventBus.$on(Const.events.SHOW_REGISTER_USER, (userData) => {
                console.log('event: SHOW_REGISTER_USER');
                this.userKnownData = userData;
                this.showDlgRegisterUser = true;
            });
            eventBus.$on(Const.events.SHOW_REGISTER_USER_VIA_EMAIL, (data) => {
                console.log('event: SHOW_REGISTER_USER_VIA_EMAIL');
                this.showDialogRegisterViaEMail(data);
            });
            eventBus.$on(Const.events.SHOW_REGISTER_USER_SUCCESS, () => {
                console.log('event: SHOW_REGISTER_USER_SUCCESS');
                this.showDlgRegisterUserSuccess = true;
            });
            eventBus.$on(Const.events.DO_LOGOUT, () => {
                console.log('event: DO_LOGOUT');
                this.doLogout();
            });
            eventBus.$on(Const.events.DO_SEARCH, (e) => {
                console.log('event: DO_SEARCH');
                this.$router.push(`/experten/${encodeURIComponent(e)}`);
            });
            eventBus.$on(Const.events.SHOW_PASSWORD_FORGOT, () => {
                console.log('event: SHOW_PASSWORD_FORGOT');
                this.showDlgPasswordForgot = true;
            });
            eventBus.$on(Const.events.DO_REGISTER_VIA_GOOGLE, (userData) => {
                console.log('event: DO_REGISTER_VIA_GOOGLE');
                this.doRegisterUserViaGoogle(userData);
            });
            eventBus.$on(Const.events.DO_REGISTER_VIA_FB, (userData) => {
                console.log('event: DO_REGISTER_VIA_FB');
                this.doRegisterUserViaFb(userData);
            });
            eventBus.$on(Const.events.DO_REGISTER_VIA_EMAIL, (userData) => {
                console.log('event: DO_REGISTER_VIA_EMAIL');
                this.doRegisterUserViaEMail(userData);
            });
        }
    }
</script>



<style>

</style>
