import Configs from "../../configs";



export const initFbsdk = () => {
    window.fbAsyncInit = function() {
        window.FB.init({
            appId: Configs.fbAuthOtions.appId,
            version: Configs.fbAuthOtions.version,
            cookie: true, // enable cookies to allow the server to access the session
            xfbml: true, // parse social plugins on this page
        })
    };

    (function (d, s, id) {
        let js, fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s); js.id = id
        js.src = '//connect.facebook.net/en_US/all.js'
        fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'));
}
