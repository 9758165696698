<template>
    <!-- login modal -->
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-md">
            <div class="modal-content p-4">
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-login-register-modal-title">Anmelden</span>
                    <div class="container px-0 mt-4">
                        <div class="row">


                            <!-- f...book / mobile -->
                            <div class="col-12 d-xs-block d-md-none" style="min-width: 325px; max-width: 325px;">
                                <button
                                    :disabled="loginInProgress"
                                    type="button"
                                    class="btn btn-mp-sm w-100 mb-2 bg-fb-blue text-white mp-qks mp-font-weight-medium font-size-registration-expert-sm-btn text-center py-2"
                                    @click="doLoginViaFacebook"
                                >
                                    <i class="fab fa-facebook-f me-2" style="float: left; padding-top: 4px"></i><span>Weiter mit Facebook</span>
                                </button>
                            </div>
                            <!-- f...book / web -->
                            <div class="col-12 d-none d-md-block" style="padding: 0px 14px 0px 26px">
                                <button
                                    :disabled="loginInProgress"
                                    type="button"
                                    class="btn btn-mp-sm w-100 mb-2 bg-fb-blue text-white mp-qks mp-font-weight-medium font-size-registration-expert-sm-btn text-center py-2"
                                    @click="doLoginViaFacebook"
                                >
                                    <i class="fab fa-facebook-f me-2" style="float: left; padding-top: 4px"></i><span>Weiter mit Facebook</span>
                                </button>
                            </div>

                            <!-- g..gle / mobile -->
                            <div class="col-12 m-0 d-xs-block d-md-none" >
                                <div :ref="`${modalId}googleLoginBtnMobile`" :id="`${modalId}googleLoginBtnMobile`"></div>
                            </div>
                            <!-- g..gle / web -->
                            <div class="col-12 m-0 d-none d-md-block" style="padding: 0px 26px 0px 26px">
                                <div :ref="`${modalId}googleLoginBtnWeb`" :id="`${modalId}googleLoginBtnWeb`"></div>
                            </div>

                        </div>

                        <div class="row my-4">
                            <div class="col-4 col-md-5 ps-0">
                                <hr />
                            </div>
                            <div class="col-4 col-md-2 d-flex justify-content-center">
                                <span class="login-modal-or-text font-size-login-modal-or-text mp-font-weight-semi-bold align-self-center mp-qks">ODER</span>
                            </div>
                            <div class="col-4 col-md-5 pe-0">
                                <hr />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 px-0">
                                <input
                                    :disabled="loginInProgress"
                                    type="email"
                                    class="form-control mb-2 py-2 font-size-login-register-modal-input"
                                    id="login-email"
                                    placeholder="E-Mail"
                                    v-model="$v.email.$model"
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 px-0">
                                <input
                                    :disabled="loginInProgress"
                                    type="password"
                                    class="form-control mb-2 py-2 font-size-login-register-modal-input"
                                    id="login-password"
                                    placeholder="Kennwort"
                                    v-model="$v.password.$model"
                                >
                            </div>
                        </div>
                        <div class="row mt-3 mb-4">
                            <div class="col-12 px-0 d-flex justify-content-center">
                                <a class="login-register-modal-forgot-password-link mp-qks mp-font-weight-semi-bold font-size-login-register-modal-forgot-password-link"
                                   href="#"
                                   @click="doPasswordForgot"
                                >KENNWORT VERGESSEN</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0 p-0">
                    <div class="container px-0">
                        <div class="row mb-4">
                            <div class="col-12">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100"
                                        :disabled="$v.$invalid || loginInProgress"
                                        @click="doLogin"
                                >
                                    <span v-if="loginInProgress" class="spinner-grow-sm text-white" role="status">Anmeldung läuft...</span>
                                    <span v-else>Anmelden</span>
                                </button>
                            </div>
                            <div class="col-12 text-danger text-center fw-bold pt-3" style="font-size: 1.2rem" v-if="loginFailed">
                                Anmeldung fehlgeschlagen!
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <span class="mp-qks mp-font-weight-semi-bold font-size-login-register-modal-bottom-addon">Noch nicht registriert?</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <button type="button"
                                        class="btn py-0 px-5 btn-login-register-modal-switch-modal font-size-btn-login-register-modal-switch-modal mp-font-weight-semi-bold"
                                        data-bs-target="#register-modal"
                                        data-bs-toggle="modal"
                                        data-bs-dismiss="modal"
                                        @click="switchToRegister(undefined)"
                                >
                                    HIER REGISTRIEREN
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- /login modal -->
</template>



<script>
    import BaseModal from "./BaseModal";
    import { required, email } from "vuelidate/lib/validators";
    import eventBus from "../../eventbus";
    import Const from "../../constants";
    import {mixinSocialAuth} from "../../mixins/socialAuth";
    import {mapActions} from "vuex";
    import Configs from "../../configs";
    import jwt_decode from "jwt-decode";


    export default {
        name: "Login",

        extends: BaseModal,

        mixins: [
            mixinSocialAuth
        ],

        data() {
            return {
                googleClientId: Configs.googleAuthOptions.client_id,
                googleClient: undefined,
                googleUserCredentials: undefined,
                modalId: 'login-modal',
                modal: undefined,
                isLoading: false,
                loginFailed: false,
                loginInProgress: false,

                email: undefined,
                password: undefined,
                remember: true,
            }
        },

        validations: {
            email: { required, email },
            password: { required}
        },

        methods: {
            ...mapActions('main', {
                _userLogin: 'userLogin',
                _userLoginFacebook: 'userLoginFacebook',
                _userLoginGoogle: 'userLoginGoogle',
                _getFbProfile: 'getFbProfile'
            }),

            async doLogin() {
                this.loginFailed = false;
                this.loginInProgress = true;
                this.loginFailed = !await this._userLogin({email: this.email, password: this.password, remember: this.remember});
                if (!this.loginFailed) {
                    this.$emit('success');
                    this.$emit('close');
                }
                this.loginInProgress = false;
            },

            doLoginViaFacebook() {
                this.loginInProgress = true;
                window.FB.login(async (res) => {
                    //console.log(res);
                    if (res.status === 'connected' && res['authResponse'] !== undefined) {
                        // user already known?
                        let fbUser = await this._getFbProfile(res.authResponse.accessToken);
                        if (fbUser.isAlreadyRegisteredWithUs) {
                            // login
                            await this._userLoginFacebook(res.authResponse.accessToken)
                            console.log('Login-fb: success');
                            this.$emit('success');
                            this.$emit('close');
                        } else {
                            // register
                            console.log('Login-fb: failed');
                            this.switchToRegister(Object.assign({loginType: 'fb', accessToken: res.authResponse.accessToken}, fbUser));
                        }
                    }
                }, { scope: 'public_profile,email', return_scopes: true })
            },

            switchToRegister(userData = undefined) {
                eventBus.$emit(Const.events.SHOW_REGISTER_USER, userData);
                this.close();
            },

            doPasswordForgot() {
                eventBus.$emit(Const.events.SHOW_PASSWORD_FORGOT);
                this.close();
            },

            async createGoogleLogin(btnRef, btnWidth = 400) {
                await window.google.accounts.id.initialize({
                    client_id: Configs.googleAuthOptions.client_id,
                    scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
                    ux_mode: 'popup',
                    callback: (response) => {
                        this.doLoginViaGoogle(response);
                    },
                });
                await window.google.accounts.id.renderButton(
                    document.getElementById(btnRef),
                    {
                        theme: "outline",
                        size: "large",
                        width: btnWidth,
                    }  // customization attributes
                );
            },

            async doLoginViaGoogle(e) {
                let res = jwt_decode(e.credential);
                let payload = {
                    googleUid: res.sub,
                    firstname: res.given_name,
                    lastname: res.family_name,
                    email: res.email
                };
                // do login
                if (await this._userLoginGoogle(payload)) {
                    // login success
                    this.$emit('success');
                    this.$emit('close');
                } else {
                    // login failed -> switch to register
                    console.log('Login-google: failed');
                    this.switchToRegister(Object.assign({loginType: 'google'}, payload));
                }
            }
        },

        async created() {
            await this.createGoogleLogin(`${this.modalId}googleLoginBtnMobile`, 300);
            await this.createGoogleLogin(`${this.modalId}googleLoginBtnWeb`);
        },

    }
</script>



<style scoped>

</style>