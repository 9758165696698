<template>
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-md">
            <div class="modal-content p-4">
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-login-register-modal-title">E-Mail Adresse verifizieren</span>
                    <div class="container px-0 mt-4">
                        <div class="row">
                            <div class="col-12 px-0">
                                <p class="mp-qks mp-font-weight-medium font-size-login-register-modal-verify-email-description">
                                    Wir haben dir ein E-Mail gesendet, um deine Registrierung abzuschließen.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0 p-0">
                    <div class="container px-0">
                        <div class="row">
                            <div class="col-12 pt-3">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" @click="close">
                                    Schließen
                                </button>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 15px">
                            <div class="col-12 d-flex justify-content-center">
                                <span class="mp-qks mp-green font-size-login-register-modal-forgot-password-link ">Code nicht erhalten? <a class="mp-green" href="#" @click="doResendToken"><b>Hier klicken</b></a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- toasts -->
        <Toast
            v-if="showToastResendToken"
            title="Verifizierungcode"
            msg="Verifizierungcode wurde nochmals versendet<br>(Hinweis: bitte auch deinen <b>SPAM-Ordner</b> überprüfen)."
            :delay="15000"
            @close="showToastResendToken = false"
        />
    </div>
</template>



<script>
    import BaseModal from "./BaseModal";
    import Toast from "../Toast";
    import {mixinBasics} from "../../mixins/basics";
    import {mapMutations, mapActions} from "vuex";


    export default {
        name: "EMailVerifyInfo",

        extends: BaseModal,

        components: {
            Toast
        },

        mixins: [
            mixinBasics
        ],

        props: {
            email: {
                required: true,
                type: String
            },
            callbackResendToken: {
                required: true,
                type: Function
            }
        },

        data() {
            return {
                modalId: 'register-user-verify-email',
                modal: undefined,
                token: undefined,
                errorMsg: undefined,
                showToastFailure: false,
                showToastResendToken: false,
                tokenWrong: false
            }
        },

        methods: {
            ...mapActions('main', {
                loadEnvironment: 'loadUserEnvironment',
            }),

            ...mapMutations('main', {
                setUserAuthToken: 'setUserAuthToken',
                setStateRegister: 'setStateRegister',
                setStateRegisterVerification: 'setStateRegisterVerification'
            }),

            async doResendToken()
            {
                let res = await this.callbackResendToken(this.email);
                if (!res.error) {
                    this.showToastResendToken = true;
                }
            },
        }

    }
</script>



<style scoped>

</style>