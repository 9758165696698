import Vue from 'vue';
import Vuex from 'vuex';
import main from './modules/main';

Vue.use(Vuex);



export default function (/* { ssrContext } */) {
    return new Vuex.Store({
        modules: {
            main
        },
        strict: false
    });
}
