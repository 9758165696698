<template>
    <div class="sticky-top bg-white">
        <nav class="navbar navbar-expand-lg navbar-light bg-light bg-white border-bottom">

            <!-- Authenticated -->
            <div class="container">
                <img src="../assets/img/mergeplate_logo.svg" style="max-width: 150px; cursor: pointer" @click="$router.push('/')" >

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navigation-main"
                        aria-controls="navigation-main"
                        aria-expanded="false"
                        aria-label="Navigation anzeigen">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse container pt-2" id="navigation-main" ref="navmain">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                        <!-- navigation -->
                        <template v-for="navItem in navItems" >
                            <li v-if="(navItem.excludedAuthUsers ? !isAuth : true)" class="nav-item" :key="`${keyPrefix}_${navItem.meta}`" @click="doNavHide">
                                <template v-if="navItem.route">
                                    <router-link :to="navItem.route" class="nav-link link-dark px-lg-4 mp-black mp-font-weight-medium font-size-top-menu-links">
                                        <span :class="currentLocation === navItem.meta ? ['nav-link-active'] : []">{{ navItem.label }}</span>
                                    </router-link>
                                </template>

                                <template v-else>
                                    <a @click="navItem.onClick" class="nav-link link-dark px-lg-4 mp-black mp-font-weight-medium font-size-top-menu-links" >
                                        <span style="cursor: pointer" :class="currentLocation === navItem.meta ? ['nav-link-active'] : []">{{ navItem.label }}</span>
                                    </a>
                                </template>
                            </li>
                        </template>
                        <!-- user/expert account -->
                        <li class="nav-item ms-lg-4" v-if="isAuth">
                            <div class="btn-group">
                                <div class="btn dropdown-toggle avatar mp-qks mp-font-weight-semi-bold mt-2 mt-lg-0" type="button" id="account-avatar" data-bs-toggle="dropdown" aria-expanded="false">
                                    {{ userInitials }}
                                </div>
                                <ul class="dropdown-menu dropdown-menu-end px-3 mt-2" aria-labelledby="account-avatar" @click="doNavHide">
                                    <!-- User-account -->
                                    <li class="pe-3"><router-link to="/user/account" class="dropdown-item font-size-top-menu-sub mp-font-weight-medium">Benutzer-Konto</router-link></li>
                                    <li><hr class="dropdown-divider"></li>

                                    <!-- Expert-account -->
                                    <template v-if="profile.role === 'expert'">
                                        <li><router-link to="/expert/account" class="dropdown-item font-size-top-menu-sub mp-font-weight-medium">Experten-Konto </router-link></li>
                                        <li><hr class="dropdown-divider"></li>
                                    </template>

                                    <li><router-link to="/faq" class="dropdown-item font-size-top-menu-sub mp-font-weight-medium" href="#">FAQ</router-link></li>
                                    <li><hr class="dropdown-divider"></li>
                                    <li><a class="dropdown-item font-size-top-menu-sub mp-font-weight-medium" href="#" @click="doLogout">Abmelden</a></li>
                                </ul>
                            </div>
                        </li>
                        <template v-else>
                            <li class="nav-item ms-lg-4">
                                <button type="button" class="btn btn-mp-green-outline mp-font-weight-bold font-size-top-menu-links mt-2 mt-lg-0 mb-3 mb-lg-0" @click="showLogin">Anmelden</button>
                            </li>
                            <li class="nav-item ms-lg-4">
                                <button type="button" class="btn btn-mp-green mp-font-weight-bold font-size-top-menu-links" @click="showRegisterExpert">Als Experte bewerben</button>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </nav>

        <!-- categories / areas -->
        <header class="d-none d-lg-block">
            <div class="container">
                <div class="row d-flex flex-wrap align-items-center justify-content-center justify-content-lg-center">
                    <div class="col-12 pt-2 pt-lg-0 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-center">

                        <ul class="nav col-lg-auto justify-content-center justify-content-lg-end">
                            <li class="nav-item dropdown has-megamenu" v-for="c in categoriesFiltered" :key="`${keyPrefix}_${c.id}_c`">
                                <router-link :to="`/fachgebiete/${c.title}/${c.id}`" class="nav-link link-dark px-4 mp-black mp-font-weight-medium font-size-top-menu-links">{{ c.title }}</router-link>
                                <div class="megamenu dropdown-menu px-3">
                                    <div class="container pb-3 py-3" >
                                        <div class="row">
                                            <template v-for="a in c.areas">
                                                <div class="col-3" :key="`${keyPrefix}_${a.id}_a`">
                                                    <router-link :to="`/fachgebiete/${c.title}/${c.id}/${a.title}/${a.id}`" class="dropdown-item font-size-top-menu-sub mp-font-weight-medium">{{ a.title }}</router-link>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </header>

    </div>
</template>


<script>
    import {mixinBasics} from "../mixins/basics";
    import Const from "../constants";
    import eventBus from "../eventbus";


    export default {
        name: "Navigation",

        mixins: [
            mixinBasics
        ],

        data() {
            return {
                keyPrefix: 'nav',

                navItems: [
                    {
                        label: 'Fachgebiete',
                        route: '/fachgebiete',
                        meta: 'category',
                        excludedAuthUsers: undefined
                    },
                    {
                        label: 'Experten',
                        route: '/experten',
                        meta: 'expert',
                        excludedAuthUsers: undefined
                    },
                    {
                        label: 'Angebote',
                        route: '/kurse',
                        meta: 'course',
                        excludedAuthUsers: undefined
                    },
                    {
                        label: 'Registrieren',
                        route: undefined,
                        onClick: this.showRegisterUser,
                        meta: 'register',
                        excludedAuthUsers: true
                    },
                ],

                searchTerm: undefined,
                Const
            };
        },

        computed: {
            currentLocation() {
                console.log('currentLocation: ' + this.$route.meta.title);
                return this.$route.meta.title;
            },

            userInitials() {
                if (this.profile) {
                    return this.profile.role === 'expert' ?
                        `${this.profile.firstname ? this.profile.firstname[0] : ''}${this.profile.lastname ? this.profile.lastname[0] : ''}`.toUpperCase() :
                        `${this.profile.nickname[0]}${this.profile.nickname[1]}`.toUpperCase();
                }
                return this.profile ? `${this.profile.firstname ? this.profile.firstname[0] : ''}${this.profile.lastname ? this.profile.lastname[0] : ''}`.toUpperCase() : '';
            },

            categoriesFiltered() {
                return this._categories.filter(c => c.areas.length > 0);
            }

        },

        methods: {
            showLogin() {
                eventBus.$emit(Const.events.SHOW_LOGIN);
            },

            showRegisterUser() {
                eventBus.$emit(Const.events.SHOW_REGISTER_USER);
            },

            showRegisterExpert() {
                this.$router.push('/register/expert/info');
            },

            doLogout() {
                eventBus.$emit(Const.events.DO_LOGOUT);
            },

            doSearch() {
                if (this.searchTerm.length >= 3) {
                    eventBus.$emit(Const.events.DO_SEARCH, this.searchTerm);
                }
            },

            doNavHide() {
                this.$refs.navmain.classList.remove('show')
            }
        },

        mounted() {
        }
    }
</script>



<style scoped>
    .nav-link-active {
        color: #7FAA2D !important;
    }
</style>