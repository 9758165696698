<template>
    <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 99">
        <!-- toast-content -->
        <div class="toast" :id="toastId" role="alert" aria-live="assertive" aria-atomic="true" :data-bs-autohide="autohide" :data-bs-duration="delay">

            <div class="toast-header text-white" :style="{'background-color': backgroundColor, 'font-size': '16px'}">
                <i class="fas fa-info-circle rounded me-2" style="font-size: 28px"></i>
                <strong class="me-auto">{{ title }}</strong>
                <!--<small>11 mins ago</small>-->
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" @click="close"></button>
            </div>

            <div class="toast-body text-black" style="font-size: 16px">
                <span v-html="msg" />
            </div>
        </div>
    </div>
</template>



<script>
    import {Toast} from 'bootstrap';
    import {mixinBasics} from "../mixins/basics";


    export default {
        name: "Toast",

        mixins: [
            mixinBasics
        ],

        data() {
            return {
                toast: undefined,
                toastId: undefined,
            }
        },

        props: {
            title: {
                type: String,
                required: true
            },
            msg: {
                type: String,
                required: true
            },
            delay: {
                type: Number,
                required: false,
                default: 5000
            },
            autohide: {
                type: Boolean,
                required: false,
                default: true
            },
            backgroundColor: {
                type: String,
                required: false,
                default: 'rgba(18,94,22,0.78)'
            }
        },

        methods: {
            close() {
                this.$emit('close');
            }
        },

        created() {
            this.toastId = `toast_${this.getRandomInt(0,100000000)}`;
        },

        mounted() {
            let elem = document.getElementById(this.toastId);
            this.toast = Toast.getOrCreateInstance(elem)
            elem.addEventListener('hidden.bs.toast', () => this.close());
            this.toast.show();
        },

        beforeDestroy() {
            this.toast.hide();
        }
    }
</script>



<style scoped>

</style>
