<template>
    <footer class="container py-5">
        <div class="row border-bottom">
            <div class="col-12 col-md-6">
                <p>
                    Auf dieser Plattform bieten unterschiedlich ausgebildete Experten ihre Dienstleistungen und Produkte an.
                    Dabei handelt es sich um Bereiche wie Ernährung und Sport, Coaching, Therapie, Lebens- und Sozialberatung,
                    Persönlichkeitsentwicklung oder auch Aus- und Weiterbildungsmöglichkeiten etc.
                    Das können Angebote wie z.B.: Einzelberatung, Strategiegespräche, Business-Coaching, genauso wie Workshops, Kurse oder Seminare. sein.
                    Produkte sind bspw. Kursmaterialien, Bücher oder ein Wochen-/Jahresplaner und vieles mehr.
                </p>
            </div>
            <div class="col-12 col-md-3 col-lg-2 offset-lg-1">
                <span class="mp-rbk mp-font-weight-bold mp-green d-block mb-3">Kategorien</span>
                <ul class="footer-link-ul">
                    <li class="footer-link-li" v-for="c in _categories" :key="`${keyPrefix}_${c.id}_c`">
                        <router-link :to="`/fachgebiete/${c.title}/${c.id}`" class="footer-link mp-black mp-font-weight-medium">{{ c.title }}</router-link>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-md-3 col-lg-2 offset-lg-1">
                <span class="mp-rbk mp-font-weight-bold mp-green d-block mb-3">Info-Links</span>
                <ul class="footer-link-ul">
                    <li class="footer-link-li"><router-link to="/ueber-uns" class="footer-link mp-black mp-font-weight-medium">Über uns</router-link></li>
                    <li class="footer-link-li"><router-link to="/kontakt" class="footer-link mp-black mp-font-weight-medium">Kontakt</router-link></li>
                    <li class="footer-link-li"><router-link to="/faq" class="footer-link mp-black mp-font-weight-medium">FAQ</router-link></li>
                    <li class="footer-link-li"><router-link to="/glossar" class="footer-link mp-black mp-font-weight-medium">Glossar</router-link></li>

                    <li class="footer-link-li"><a @click="openExternalUrl('https://mergeplate.com/docs/AGB_NB.pdf', 'AGB')" style="cursor: pointer" class="footer-link mp-black mp-font-weight-medium">AGB & Nutzungsbedingungen</a></li>
                    <li class="footer-link-li"><a @click="openExternalUrl('https://mergeplate.com/docs/Teilnahmebedingungen_Bonussystem.pdf', 'Nutzungsbedingungen')" style="cursor: pointer" class="footer-link mp-black mp-font-weight-medium">Teilnahmebedingungen Bonussystem</a></li>
                    <li class="footer-link-li"><a @click="openExternalUrl('https://mergeplate.com/docs/Datenschutzerklaerung.pdf', 'Datenschutzerklärung')" style="cursor: pointer" class="footer-link mp-black mp-font-weight-medium">Datenschutzerklärung</a></li>

                    <!--
                    <li class="footer-link-li"><a href="#" @click="dl('AGB_merge_plate.pdf')" class="footer-link mp-black mp-font-weight-medium">AGB</a></li>
                    <li class="footer-link-li"><a href="#" @click="dl('Nutzungsbedingungen_merge_plate.pdf')" class="footer-link mp-black mp-font-weight-medium">Nutzungsbedingungen</a></li>
                    <li class="footer-link-li"><a href="#" @click="dl('Datenschutzerklärung_merge_plate.pdf')" class="footer-link mp-black mp-font-weight-medium">Datenschutzerklärung</a></li>
                    -->
                    <li class="footer-link-li"><router-link to="/impressum" class="footer-link mp-black mp-font-weight-medium">Impressum</router-link></li>
                </ul>
            </div>
        </div>

        <div class="row pt-4 d-none d-md-flex">
            <div class="col-8 col-md-10">
                <!-- <a class="footer-link me-4 mp-font-weight-semi-bold mp-green font-size-logo" href="#">Logo</a> -->
                <img src="../assets/img/mergeplate_logo.svg" style="max-width: 110px; margin-right:25px; cursor: pointer" @click="$router.push('/')">
                <span class="mp-qks mp-font-weight-bold font-size-footer-copy">&copy; {{ new Date().getFullYear() }} mergeplate.com | Design &amp; Development by <a class="mp-green text-decoration-none" href="https://apptec.at" target="_blank">apptec</a></span>
            </div>
            <div class="col-4 col-md-2 d-flex justify-content-end">
                <a class="mp-green footer-social-icon pe-3" href="https://www.facebook.com/profile.php?id=61553774777398" target="_blank"><i class="fab fa-facebook-square"></i></a>
                <a class="mp-green footer-social-icon" href="https://www.instagram.com/mergeplate_com/" target="_blank"><i class="fab fa-instagram"></i></a>
            </div>
        </div>

        <div class="row pt-4 d-flex d-md-none">
            <div class="col-8">
                <img src="../assets/img/mergeplate_logo.svg" style="max-width: 110px; margin-right:25px; cursor: pointer" @click="$router.push('/')">
            </div>
            <div class="col-4 d-flex justify-content-end">
                <a class="mp-green footer-social-icon pe-3" href="https://www.facebook.com/profile.php?id=61553774777398" target="_blank"><i class="fab fa-facebook-square"></i></a>
                <a class="mp-green footer-social-icon" href="https://www.instagram.com/mergeplate_com/" target="_blank"><i class="fab fa-instagram"></i></a>
            </div>
            <div class="col-12 text-center pt-2 ">
                <span class="mp-qks mp-font-weight-bold font-size-footer-copy">&copy; {{ new Date().getFullYear() }} mergeplate.com | Design &amp; Development by <a class="mp-green text-decoration-none" href="https://apptec.at/" target="_blank">apptec</a></span>
            </div>
        </div>
    </footer>
</template>



<script>
    import {mixinBasics} from "../mixins/basics";
    //import {fileDownload} from "../helpers";
    //import Configs from "../configs";


    export default {
        name: "Footer",

        data() {
            return {
                keyPrefix: 'foot',
            };
        },

        mixins: [
            mixinBasics
        ],

        methods: {
            openExternalUrl(url, title = 'PDF') {
                let wh = window.open();
                wh.document.write(`<html>
                      <head>
                          <title>${title}</title>
                      </head>
                      <body style="margin: 0; padding: 0">
                          <iframe src="${url}" style="width: 100%; height: 100%; margin: 0; padding: 0; border: none;"></iframe>
                      </body>
                    </html>`);
            }
        }
    }
</script>



<style scoped>

</style>
