<template>
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-md">

            <!-- req. token: step 1 -->
            <div v-if="step === 1" class="modal-content p-4">
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-login-register-modal-title">{{title}}</span>
                    <span class="d-block text-center mp-rbk mt-4">Wir werden dir ein E-Mail mit entsprechenden Anweisungen zusenden.</span>
                    <div class="container px-0 mt-4">
                        <div class="row">
                            <div class="col-12 px-0">
                                <input
                                    type="text"
                                    :disabled="!!profile.email"
                                    class="form-control mb-2 py-2 font-size-login-register-modal-input"
                                    v-model="email"
                                    placeholder="E-Mail Adresse hier eingeben"
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0 p-0 mt-4">
                    <div class="container px-0">
                        <div class="row mb-4">
                            <div class="col-6">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" :disabled="isLoading" @click="close">
                                    Abbrechen
                                </button>
                            </div>
                            <div class="col-6">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" :disabled="isLoading || $v.$invalid" @click="doSendPasswordChangeToken">
                                    Absenden
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- req. token: step 2 -->
            <div class="modal-content p-4" v-if="step === 2">
                <div class="modal-header border-0 p-0 d-flex justify-content-center">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
                </div>
                <div class="modal-body py-0">
                    <span class="d-block text-center mp-rbk mp-font-weight-semi-bold font-size-login-register-modal-title">{{title}}</span>
                    <span class="d-block text-center mp-rbk mt-4">E-Mail wurde versendet, bitte überprüfe deinen Posteingang.</span>
                </div>
                <div class="modal-footer border-0 p-0 mt-4 mb-2">
                    <div class="container px-0">
                        <div class="row">
                            <div class="col-6 offset-3">
                                <button type="button" class="btn btn-mp-green font-size-btn-login-register-modal-submit mp-font-weight-medium py-2 px-5 w-100" :disabled="isLoading" @click="close">
                                    Schließen
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>




<script>
    import Conf from "../../configs";
    import BaseModal from "./BaseModal";
    import {email, required} from "vuelidate/lib/validators";
    import {apiCall} from "../../helpers";
    import {mixinBasics} from "../../mixins/basics";


    const api = Conf.restApi.endpoints.map;


    export default {
        name: "PasswordChangeReq",

        extends: BaseModal,

        mixins: [
            mixinBasics
        ],

        props: {
        },

        data() {
            return {
                modalId: 'password-change-modal',
                modal: undefined,
                isLoading: false,

                toastMsg: '',
                toastTitle: '',
                toastBgColor: undefined,
                showToast: false,

                email: undefined,
                step: 1
            }
        },

        computed: {
            title() {
                return this.profile.email ? 'Kennwort ändern' : 'Kennwort vergessen';
            }
        },

        validations: {
            email: {
                required, email
            },
        },

        methods: {
            async doSendPasswordChangeToken() {
                this.isLoading = true;
                let req = api.user.password.requestToken
                await apiCall(req.url, req.method, {email: this.email});
                this.isLoading = false;
                this.step = 2;
            },
        },

        mounted() {
            this.email = this.profile.email ? this.profile.email : undefined;
        }
    }
</script>



<style scoped>

</style>