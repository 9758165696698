import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Vuelidate from 'vuelidate';
import store from './store';
import 'bootstrap';
import {initFbsdk} from "./assets/js/fbAuth";

// css
import './assets/css/google_fonts.css';
import './assets/fontawesome/css/all.min.css';
import './assets/css/bootstrap.min.css';
import './assets/css/styles.css';


import x5GMaps from 'x5-gmaps';
import Configs from "./configs";
import VueMeta from "vue-meta";


Vue.config.productionTip = false;


//------------------------------------------------------------------------------------
//                                                                       Plugins-Init.
//------------------------------------------------------------------------------------
Vue.use(VueMeta, {attribute: 'manage'});
Vue.use(Vuelidate);
Vue.use(x5GMaps, Configs.googleMaps.apiKey);


//------------------------------------------------------------------------------------
//                                                                      F...book init.
//------------------------------------------------------------------------------------
initFbsdk();


//------------------------------------------------------------------------------------
//                                                                         Store-Init.
//------------------------------------------------------------------------------------
(async () => { await store().dispatch('main/initStore'); })();


//------------------------------------------------------------------------------------
//                                                                           App-Start
//------------------------------------------------------------------------------------
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
