<template>
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-md">
            BaseModal Component to DERIVE from ;)
        </div>
    </div>
</template>



<script>
    import { Modal } from 'bootstrap';

    export default {
        name: "BaseModal",

        data() {
            return {
                modalId: 'theModal',
                modal: undefined,
            }
        },

        methods: {
            close() {
                this.$emit('close');
            }
        },

        mounted() {
            this.modal = new Modal(document.getElementById(this.modalId));
            this.modal.show();
        },

        beforeDestroy() {
            this.modal.hide();
        }
    }
</script>



<style scoped>

</style>
